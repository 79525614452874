import React from "react";
import "./styles/banner.css";

export default function Banner() {
  return (
    <div className="banner" id="about">
      <div className="banner-img">
        <img src="/images/banner-img.png" alt="banner-img"></img>
      </div>
      <div className="banner-text">
        <h2> ¿Estás listo para impulsar tu negocio?</h2>
        <p>
          En BytePeak nos especializamos en ofrecerte la asesoría y servicios
          tecnológicos necesarios para construir y hacer crecer tu negocios e
          identidad digital. <br />
          <br />
          Desde el diseño de tu sitio web hasta la implementación de estrategias
          de marketing digital y asesoria sobre infraestructura tecnologica,
          estamos aquí para ayudarte en cada paso del camino. <br />
          <br />
          ¡Empieza a construir tu futuro digital hoy mismo!
        </p>
        <button className="cta-button">
          <a href="https://form.jotform.com/bytepeaktechnology/contact-form-BytePeak">
            Primera asesoria GRATIS!
          </a>
        </button>
      </div>
    </div>
  );
}
