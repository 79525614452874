import React from "react";
import "./styles/header.css";

export default function Home() {
  return (
    <>
      <header className="header" id="home">
        <div>
          <img className="header-logo" src="/images/logo2.png" alt="logo"></img>
        </div>
        <div className="nav-button-conteiner">
          <nav className="navbar">
            <ul>
              <li>
                <a href="https://www.instagram.com/bytepeak?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==">
                  <img src="/images/instagram.svg" alt="instagram"></img>
                </a>
              </li>
              <li>
                <a href="https://twitter.com/BytePeak_Tech">
                  <img src="/images/twitter-x.svg" alt="twitter-x"></img>
                </a>
              </li>
              <li>
                <a href="https://www.facebook.com/profile.php?id=61559110281183&mibextid=ZbWKwL">
                  <img src="/images/facebook.svg" alt="facebook"></img>
                </a>
              </li>
              <li>
                <a href="https://form.jotform.com/bytepeaktechnology/contact-form-BytePeak">
                  <img src="/images/mail-share.svg" alt="mail"></img>
                </a>
              </li>
            </ul>
          </nav>
          <div className="cta">
            <a className="promo-link" href="#promos">
              <button class="button" data-text="Promociones">
                <span class="actual-text">&nbsp;Promociones&nbsp;</span>
                <span aria-hidden="true" class="hover-text">
                  &nbsp;Promociones&nbsp;
                </span>
              </button>
            </a>
          </div>
        </div>
      </header>
    </>
  );
}
