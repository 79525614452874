import React from "react";
import "./styles/footer.css";

export default function Footer() {
  return (
    <div className="footer">
      <div className="contact" style={{ paddingBottom: "1rem" }}>
        <div className="logo-footer">
          <a href="#home">
            <img src="/images/logo2.png" alt="logo-footer"></img>
          </a>
        </div>
        <div className="rrss-conteiner">
          <h4>Siguenos</h4>

          <div className="rrss-conteiner-foot">
            <a href="https://www.instagram.com/bytepeak?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==">
              <img src="/images/instagram-foot.svg" alt="instagram-link"></img>
            </a>
            <a href="https://twitter.com/BytePeak_Tech">
              <img src="/images/twitter-foot.svg" alt="twitter-link"></img>
            </a>
            <a href="https://www.facebook.com/profile.php?id=61559110281183&mibextid=ZbWKwL">
              <img src="/images/facebook-foot.svg" alt="faceboo-link"></img>
            </a>
          </div>
        </div>
        <div className="footer-navbar">
          <ul>
            <li>
              <a href="#home">Inicio</a>
            </li>
          </ul>

          <ul>
            <li>
              <a href="#about">Nosotros</a>
            </li>
          </ul>
          <ul>
            <li>
              <a href="#promos">Nuestra Promo.</a>
            </li>
          </ul>
          <ul>
            <li>
              <a href="#services">Servicios</a>
            </li>
          </ul>
          <ul>
            <li>
              <a href="https://form.jotform.com/bytepeaktechnology/contact-form-BytePeak">
                Contactanos
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className="copy-rrss-conteiner">
        <div className="copy">
          <p>Build by BytePeak | © 2023 All Rights Reserved.</p>
          <p> Estados Unidos | España | Venezuela</p>
        </div>
      </div>
    </div>
  );
}
