import React from "react";
import "./styles/about.css";

export default function About() {
  return (
    <section className="about" id="home">
      <div className="image">
        <img src="/images/about-back.png" alt=""></img>
      </div>
      <div className="title">
        <p>
          Llevamos tu idea al
          <span>
            <br />
            MUNDO DIGITAL
          </span>
        </p>
        <button className="cta-about-button">
          <a href="#services">Consulta Nuestros Servicios.</a>
        </button>
      </div>
    </section>
  );
}
