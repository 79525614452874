// TelegramButton.js

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTelegram } from "@fortawesome/free-brands-svg-icons";

const TelegramButton = () => {
  return (
    <div className="telegram-button" style={styles.container}>
      <a
        href="https://t.me/BytePeak"
        target="_blank"
        rel="noopener noreferrer"
        style={styles.link}
      >
        <FontAwesomeIcon icon={faTelegram} style={styles.icon} />
      </a>
    </div>
  );
};

const styles = {
  container: {
    position: "fixed",
    bottom: "5rem",
    right: "1rem",
    zIndex: 1000,
  },
  link: {
    backgroundColor: "#0088cc",
    color: "#fff",
    borderRadius: "50%",
    padding: ".5rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
  },
  icon: {
    fontSize: "1.5rem",
  },
};

export default TelegramButton;
