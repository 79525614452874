import React from "react";
import "./styles/promo.css";

export default function Promo() {
  return (
    <section id="promos">
      <div className="promos">
        <div className="promo-content">
          <h2>Quieres una Pagina Web que destaque?</h2>
          <p>
            ¡Deja de perder clientes!
            <br />
            <br />
            Desarrollamos tu página web profesional, personalizada y optimizada
            para convertir visitantes en clientes.
            <br />
            <br />
            Olvída las complicaciones, nosotros nos encargamos de todo: diseño,
            contenido, SEO, hosting y mantenimiento.
            <br />
            <br />
            ¡Empieza a crecer hoy mismo! Solicita tu presupuesto sin compromiso
            y descubre cómo una página web puede impulsar tu negocio.
          </p>
          <button className="cta-button">
            <a href="https://form.jotform.com/bytepeaktechnology/formulariodesolicitud">
              Podemos Ayudarte!
            </a>
          </button>
        </div>
        <div className="promo-img">
          <img src="./images/promo-img.png" alt="promo-img"></img>
        </div>
      </div>
    </section>
  );
}
