import React from "react";
import "./styles/services.css";

export default function Services() {
  return (
    <section className="services" id="services">
      <h2 style={{ color: "white", justifyContent: "center" }}>
        Consulta nuestros servicios.
      </h2>
      <div className="cards">
        <div class="card">
          <div class="first-content">
            <div className="card-img">
              <img src="./images/Consultant.jpg" alt="Consultoria"></img>
            </div>
            <div>
              <h3>Consultoria TI</h3>
            </div>
          </div>
          <div class="second-content">
            <p>
              Nuestro servicio de consultoría IT está diseñado para fortalecer
              tu infraestructura tecnológica y llevar tu negocio al futuro
              digital.
              <br />
              <br /> Desde la seguridad de datos hasta la optimización de redes,
              nuestro equipo está aquí para ayudarte a alcanzar tus objetivos.
            </p>
          </div>
        </div>
        <div class="card">
          <div class="first-content">
            <div className="card-img">
              <img src="/images/web1.jpg" alt="Desarrollo Web"></img>
            </div>
            <div>
              <h3>Desarrollo Web</h3>
            </div>
          </div>
          <div class="second-content">
            <p>
              Creamos sitios y aplicaciones web que haran que tu presencia
              destaque en el mundo digital. <br />
              <br />
              Nuestro equipo experto se encarga de cada detalle, desde la
              maquetacion hasta la publicacion para desarrollar tu proyecto tal
              como lo imaginaste.
            </p>
          </div>
        </div>
      </div>
      <p>
        Nuestro equipo cuenta con mas de 10 años de experiencia en el desarrollo
        de soluciones tecnologicas <br /> y en Byte Peak los ponemos a tu
        servicio.
      </p>
      <button className="cta-button">
        <a href="https://form.jotform.com/bytepeaktechnology/formulariodesolicitud">
          Hablemos de tu proximo proyecto!
        </a>
      </button>
    </section>
  );
}
